/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */

function scrollToAnchor(location) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop;

    window.scrollTo({
      top: item - 72,
      behavior: 'smooth',
    });
  }

  return true;
}

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location);
